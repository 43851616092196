<template>
  <main class="main-content js-focus-hidden" id="MainContent" role="main" tabindex="-1">
    <div class="page_banner top_header_div load_header_div">
      <div class="container">
        <p class="subtitle">
          <a id="customer_logout_link">ADD</a>
        </p>
        <h2 class="f-hl" style>
          <div
            class="f-hl-line animtiontitle"
            style="display: block; text-align: start; position: relative;"
          >Size Chart</div>
        </h2>
      </div>
    </div>
    <div class="section-bottom">
        <div class="left">
            <form v-on:submit="sizeChart()" class="f-contact">
                <p id="showmessage">Your message has been successfully sent.</p>
                <p class="f-prr">Shirt Size</p>
                <input type="number" step="0.01" class="f-contact-m" placeholder="Depth of Dart" v-model="size_chart.depth_of_dart" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Empire Length" v-model="size_chart.empire_length" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Length of Waist Down" v-model="size_chart.length_of_waist_down" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Low Waist Down" v-model="size_chart.low_waist_down" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Hip Down" v-model="size_chart.hip_down" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Shirt/Blouse Length" v-model="size_chart.shirt_length" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Upper Bust" v-model="size_chart.upper_bust" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Bust" v-model="size_chart.bust" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Empire" v-model="size_chart.empire" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Waist" v-model="size_chart.waist" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Low Waist" v-model="size_chart.low_waist" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Hip" v-model="size_chart.Hip" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Neck Front Depth" v-model="size_chart.neck_front_depth" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Neck Back Depth" v-model="size_chart.neck_back_depth" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Neck Width" v-model="size_chart.neck_width" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Shoulder" v-model="size_chart.shoulder" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Armhole" v-model="size_chart.armhole" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Bicep" v-model="size_chart.bicep" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Seleve Length" v-model="size_chart.seleve_length" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Cap" v-model="size_chart.cap" />
                <!-- <input type="number" step="0.01" class="f-contact-m" placeholder="3/4" v-model="email" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Full" v-model="email" /> -->
                <input type="number" step="0.01" class="f-contact-m" placeholder="Cuff" v-model="size_chart.Cuff" />
                <p class="f-prr">Trouser Size</p>
                <input type="number" step="0.01" class="f-contact-m" placeholder="Length" v-model="size_chart.trouser_length" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Waist" v-model="size_chart.trouser_waist" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Hips" v-model="size_chart.trouser_hips" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Thigh" v-model="size_chart.trouser_thigh" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Calf" v-model="size_chart.trouser_calf" />
                <input type="number" step="0.01" class="f-contact-m" placeholder="Bottom" v-model="size_chart.trouser_bottom" />
                <p class="f-prr">Comments</p>
                <textarea class="f-contact-t-a" placeholder="Remarks" v-model="size_chart.remarks" />
                <button class="f-contact-btn" type="submit" v-if="userInfo.size_chart">
                    <p class="f-prr" id="save-btn" >Update Size Chart</p>
                </button>
                <button class="f-contact-btn" type="submit" v-else>
                    <p class="f-prr" id="save-btn" >Edit Size Chart</p>
                </button>
            </form>
        </div>
        <div class="right">
            <img src="../assets/sizeChart.jpeg" alt="">
        </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
export default {
  components: {
    Signin: () => import("@/components/Signin.vue"),
    Signup: () => import("@/components/Signup.vue"),
    loader: () => import("../components/loader.vue")
  },
  data() {
    return {
      userInfo: {},
      orders: {},
      size_chart: {},
      error: false,
      currency: ""
    };
  },
  mounted() {
    this.init();
  },
  created() {
    setTimeout(() => {
      this.init();
      if (localStorage.getItem("currency")) {
        this.currency = JSON.parse(localStorage.getItem("currency"));
      }
    }, 500);
  },
  methods: {
    init() {
      this.$store.dispatch("Store");
      this.userInfo = this.$store.state.customer;
      if(this.userInfo && this.userInfo.size_chart){
        this.size_chart = this.userInfo.size_chart
      }
    },
    logout(){
        localStorage.removeItem('users')
        this.$router.push({path:'/page/home'})
        location.reload()
    },
    sizeChart(e){
      event.preventDefault();
      document.getElementById("save-btn").disabled = true;
      document.querySelector('#save-btn').innerHTML = 'Updating....';
      //  console.log(this.userInfo, 'userInfo')
       this.userInfo.size_chart = this.size_chart
        // console.log(this.size_chart, 'size_chart')
        // console.log(this.userInfo, 'userInfo + sizechart')
        axios
        .post(this.$store.state.api + "users/_update/" + this.userInfo.id, {
          doc: this.userInfo
        })
        .then(response => {
           var x = document.getElementById("showmessage");
          x.className = "active";
          document.getElementById("save-btn").disabled = false;
          document.querySelector('#save-btn').innerHTML = 'Update Size Chart';
          // console.log(response, "updateded");
          localStorage.setItem("users", JSON.stringify(this.userInfo));
          setTimeout(function(){ 
                x.className = x.className.replace("active", ""); 
          }, 3000);
          // alert(done);
        });
      alert(done)
    }
  }
};
</script>

<style scoped>
.section-bottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.left {
  width: 50%;
  min-width: 40%;
  padding: 5%;
}
.left h4{
    margin: 5% 0;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: inherit;
    font-family: "Saol Display";
    margin-bottom: .5rem;
    line-height: 1.1;
    color: inherit;
    margin-top: 0;
}
.right {
  width: 50%;
  min-width: 40%;
  padding: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.right h4{
    margin: 5% 0;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: inherit;
    font-family: "Saol Display";
    margin-bottom: .5rem;
    line-height: 1.1;
    color: inherit;
    margin-top: 0;
}
.f-bp{
    margin-top: 0%;
}
.main-content {
    position: relative;
    z-index: 1;
    background: #fff;
}
.page_banner {
    text-align: center;
    padding: 150px 0px 100px;
    background-color: #f7f8f6;
    min-height: 425px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page_middle {
    padding: 100px 0px;
    min-height: 500px;
}
.container {
    padding-right: 80px;
    padding-left: 80px;
}
.subtitle {
    font-size: 10px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 45px;
}
.animtiontitle {
    font-weight: 400;
    letter-spacing: 0;
    text-transform: inherit;
    font-family: "Saol Display";
    margin-bottom: .5rem;
    line-height: 1.1;
    color: inherit;
    margin-top: 0;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.s-banner h3 {
  display: block;
  text-align: start;
  position: relative;
  transform-origin: 0px 0px;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  opacity: 0.8;
  font-weight: bold;
  font-size: 0.688vw;
  line-height: 0.688vw;
  margin-bottom: 3.75vw;
  letter-spacing: 0.188vw;
  text-transform: uppercase;
  font-family: "Aktiv Grotesk";
}
.s-banner h2 {
  font-weight: 300;
  font-size: 4.188vw;
  line-height: 4.5vw;
  margin-bottom: 3vw;
  letter-spacing: -0.179vw;
  font-family: "Saol Display";
}
.s-banner p {
  opacity: 0.8;
  font-size: 0.938vw;
  font-weight: normal;
  line-height: 1.875vw;
  letter-spacing: 0.034vw;
  font-family: "Aktiv Grotesk";
}
.f-nr {
  color: #ae97a2;
  font-weight: 300;
  font-size: 12.5vw;
  line-height: 9.688vw;
  letter-spacing: -0.665vw;
  font-family: "Saol Display";
}
.f-hs {
  font-weight: 300;
  font-size: 2.688vw;
  line-height: 2.896vw;
  letter-spacing: -0.104vw;
  font-family: "Saol Display";
}
.f-pr {
  opacity: 0.8;
  font-size: 0.938vw;
  font-weight: normal;
  line-height: 1.875vw;
  letter-spacing: 0.034vw;
  font-family: "Aktiv Grotesk";
  text-align: justify;
}
.s-values .f-pr,
.s-banner-text p {
  width: calc(100vw / 15 * 3);
}
.s-values {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(100vw / 20 * 2);
  margin-right: calc(100vw / 20 * 2);
  padding-bottom: 16.25vw;
  margin-bottom: 0;
}

.s-header-text {
  display: flex;
  height: 35vh !important;
  align-items: flex-end;
  margin-bottom: 50px;
}
.s-header-scroll {
  bottom: 1vw;
}
.f-contact {
  width: 100%;
  padding: 1%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.f-contact .f-contact-m {
  height: 4vw;
  color: #1f1f1f;
  font-size: 0.938vw;
  font-weight: normal;
  font-family: "Aktiv Grotesk";
  outline: 0;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0 1.25vw;
  line-height: 3.563vw;
  letter-spacing: 0.034vw;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #d2d2d2;
  width: 31%;
  margin: 5px;
}
.f-contact .f-contact-l {
  height: 4vw;
  color: #1f1f1f;
  font-size: 0.938vw;
  font-weight: normal;
  font-family: "Aktiv Grotesk";
  outline: 0;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0 1.25vw;
  line-height: 3.563vw;
  letter-spacing: 0.034vw;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #d2d2d2;
  width: 99%;
  margin: 1%;
}
.f-contact .f-contact-t-a {
  height: 8vw;
  color: #1f1f1f;
  font-size: 0.938vw;
  font-weight: normal;
  font-family: "Aktiv Grotesk";
  outline: 0;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0 1.25vw;
  line-height: 3.563vw;
  letter-spacing: 0.034vw;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #d2d2d2;
  width: 99%;
  margin: 1%;
}
.f-contact .f-contact-btn {
  width: 98%;
  margin: 1% 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #232323 !important;
  color: #fff;
  padding: 4% 0;
}
.f-contact .f-prr {
  width: 100%;
  margin: 4% 1%;
  font-family: "Saol Display";
}
.f-contact .f-contact-btn .f-prr {
  margin: 0;
  font-family: "Aktiv Grotesk";
}
.f-contact .f-contact-m {
  width: 30%;
  height: 4vw;
  color: #1f1f1f;
  font-size: 0.938vw;
  font-weight: normal;
  font-family: "Aktiv Grotesk";
  outline: 0;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0 1.25vw;
  line-height: 3.563vw;
  letter-spacing: 0.034vw;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #d2d2d2;
}

@media only screen and (max-width: 580px) {
  .s-banner p {
    margin-left: calc(100vw / 12 * 1);
  }
  .s-values .f-pr,
  .s-banner-text p {
    width: calc(100vw / 12 * 8);
    font-size: 4.167vw;
    line-height: 7.5vw;
    letter-spacing: 0.15vw;
  }
  .s-banner h2 {
    font-size: 11.111vw;
    line-height: 11.944vw;
    margin-bottom: 8.333vw;
    letter-spacing: -0.417vw;
  }
  .s-banner h3 {
    font-size: 2.778vw;
    line-height: 4.167vw;
    margin-bottom: 5.556vw;
    letter-spacing: 0.75vw;
  }
  section,
  figure {
    margin: 13vw 0;
  }
  .s-values {
    display: block;
    flex-wrap: unset;
    margin-left: calc(100vw / 12 * 1);
    margin-right: calc(100vw / 12 * 1);
    padding-bottom: 16.25vw;
    margin-bottom: 0;
  }
  .s-values-block {
    width: auto;
    padding: 13.889vw 0;
    border-top: 1px solid #e8d6ca;
  }
  .s-values .f-nr {
    margin: 22.222vw 0 13.889vw;
    font-size: 55.556vw;
    line-height: 41.556vw;
    letter-spacing: -2.956vw;
  }
  .s-values .f-hs {
    margin-bottom: 6.944vw;
    font-size: 11.111vw;
    line-height: 11.944vw;
    letter-spacing: -0.417vw;
  }
  .s-values .f-pr {
    width: calc(100vw / 12 * 8);
    font-size: 4vw;
    line-height: 6.5vw;
    letter-spacing: 0.15vw;
    text-align: left;
  }
  .section-appointment {
      flex-wrap: wrap-reverse;
      align-items: center;
      justify-content: center;
  }
  .left {
    width: 90%;
    padding: 5%;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  .f-hl-line{
    text-align: center  !important;
  }
  .f-contact{
    justify-content: center;
    align-items: center;
  }
  .f-contact .f-prr{
      text-align: center;
  }
  .right {
    width: 90%;
    padding: 5%;
    justify-content: center;
    align-items: center;
  }
  .f-contact .f-contact-m{
      height: 9vw !important;
      width: 100%;
      font-size: 12px;
      padding: 0 2.25vw;
  }
  .f-contact .f-contact-l{
      height: 7vw;
      width: 100%;
      font-size: 12px;
  }
  .f-contact .f-contact-t-a{
      height: 15vw;
      width: 100%;
      font-size: 12px;
      padding: 2% 2%;
  }
}


#showmessage{
    background: #d2e4c4;
    color: #307a07;
    padding: 10px 20px;
    font-size: 14px;
    display: none;
    margin-top: 10px;
    margin: 10px auto 0;
}


.active {
   /* transform: translateY(-100px) !important; */
   display: flex !important;
}

</style>

